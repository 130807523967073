.client {
    text-align: center;
    font-size: 18px;

    .subtitle {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 24px;
    }

    .field-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 30px;

        .field {
            display: flex;
            flex-direction: column;

            input {
                width: 20vw;
                min-width: 250px;
                margin: 0 auto;
            }
        }
    }

    button,
    .button {
        margin-top: 15px;
    }

    .loans {
        overflow-x: auto;

        table {
            border-collapse: collapse;
            margin: 0 auto;

            th,
            td {
                padding: 7px;
                border: 1px solid black;

                &.clickable {
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(235, 235, 235);
                    }
                }
            }
        }
    }
}