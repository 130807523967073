.searchPage {
    .lists {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;

        button {
            font-size: 16px;
            background-color: rgb(255, 255, 255);
            margin: 0;

            &:hover,
            &.selected {
                background-color: rgb(225, 225, 225);
            }
        }
    }

    table {
        min-width: 75%;
    }

    .noresults {
        text-align: center;
        font-size: 22px;
    }
}