.newFile {
    .sectionName {
        margin: auto;
        display: inline-block;
        font-size: 26px;
    }

    .sectionClean {
        padding: 5px;
        margin: 0 10px;
        font-size: 15px;
    }

    .section {
        display: flex;
        margin-bottom: 15px;

        .form {
            padding: 5px;
            flex-grow: 1;

            .field {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 5px;
                padding: 2px 0;
                font-size: 18px;

                input:not([type]),
                input[type=text],
                .autocomplete,
                .troubleshooting-payment-input {
                    flex-grow: 1;
                }

                .troubleshooting-payment-input {
                    justify-content: start;
                }

                .autocomplete {
                    input {
                        width: 100%;
                    }

                    .options .option {
                        padding: 4px 5px;
                    }
                }

                .react-datepicker-wrapper {
                    width: auto;
                }
            }
        }

        .separator {
            margin: 0 10px;
            border: black solid 0;
            border-right-width: 2px;
        }

        .results {
            display: flex;
            width: 65%;
            margin: 5px;
            overflow: auto;

            span {
                margin: auto;
                font-size: 20px;
            }

            table {
                width: 100%;
                height: max-content;
                border-collapse: collapse;

                th,
                td {
                    padding: 7px;
                    border: 1px solid black;
                }

                tr td {
                    cursor: pointer;
                }

                tr:hover td {
                    background-color: rgb(230, 230, 230);
                }
            }
        }

        @media (orientation: portrait) {
            flex-wrap: wrap;

            .form {
                width: 100%;

                .field {
                    padding: 5px 0;
                }

                .field:not(.no-mobile-wrap) {
                    flex-direction: column;
                    align-items: flex-start;

                    input:not([type]),
                    input[type=text],
                    .autocomplete,
                    .troubleshooting-payment-input {
                        width: 100%;
                    }

                    .troubleshooting-payment-input {
                        justify-content: center;
                    }
                }
            }

            .separator {
                display: none;
            }

            .results {
                width: 100%;
            }
        }
    }
}