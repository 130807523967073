@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@keyframes bounceIn {

    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

body {
    font-family: sans-serif;
    margin: 10px;
    margin-top: 0;
    margin-bottom: 25px;
}

.header {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 25px;
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: auto;

        .left {
            display: flex;
            gap: 25px;

            .logo {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 20px;

                img {
                    height: 50px;
                }
            }

            .links {
                display: flex;
                align-items: center;
                gap: 10px;

                a {
                    white-space: nowrap;
                }
            }
        }

        .search {
            display: flex;
            align-items: center;
            width: 14%;
            min-width: 175px;
            margin-right: 10px;
            border: 1px solid #dfe1e5;
            border-radius: 20px;
            transition-duration: 250ms;
            transition-property: width;

            i {
                box-sizing: border-box;
                padding: 9.5px 7px 9.5px 15px;
                color: rgb(50, 50, 50);
                cursor: pointer;
            }

            input {
                padding: 7px 10px 7px 0;
                flex: 1;
                height: 100%;
                outline: none;
                border: none;
                border-radius: 20px;
                font-size: 16px;
                overflow: hidden;
            }

            &:focus-within,
            &:hover {
                width: 19%;
                box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
            }
        }
    }

    .line {
        border-bottom-color: black;
        border-bottom-width: 2px;
        border-bottom-style: solid;
    }
}

.loading {
    width: 100%;
    text-align: center;

    i {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 32px;
        color: black;
        animation: rotation 1s infinite linear;
    }
}

.info {
    margin: 10px auto;
    width: max-content;
    padding: 10px;
    font-size: 18px;
    color: red;
    border: 1px solid;
    border-radius: 5px;
    animation-duration: 0.75s;
    animation-name: bounceIn;
}

button,
.button {
    display: inline-block;
    margin: 5px;
    padding: 7px;
    background-color: rgb(225, 225, 225);
    font-size: 18px;
    cursor: pointer;
    border-width: 0;
    border-radius: 5px;
    transition-duration: 75ms;
    transition-property: background-color, transform;

    &:active {
        transform: translateY(2px);
    }

    &:hover {
        background-color: rgb(200, 200, 200);
    }
}

input:not([type=radio]),
select {
    box-sizing: border-box;
    padding: 4px 5px;
    font-size: 14px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    outline: none;

    &:disabled {
        background-color: rgb(230, 230, 230);
    }
}

.title {
    text-align: center;
    margin: 10px 0;
    font-size: 35px;
}

.box {
    margin: auto;
    margin-bottom: 15px;
    width: max-content;
    box-sizing: border-box;
    max-width: 100%;
    border: black 1px solid;
    border-radius: 10px;
    padding: 10px;
    text-align: center;

    .title {
        font-size: 22px;
        margin: 5px 0;
    }
}

a {
    text-decoration: none;
    color: black;
}

.link {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        transform-origin: 0 0;
        transform: scaleX(0);
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: currentColor;
        transition: transform 250ms;
    }

    &.active::after,
    &:hover::after {
        transform: scaleX(1);
    }
}

.link-container:hover .link::after {
    transform: scaleX(1);
}

.files {
    overflow-x: auto;

    table {
        border-collapse: collapse;
        margin: 0 auto;

        th,
        td {
            padding: 7px;
            border: 1px solid black;
        }

        tr {
            td {
                cursor: pointer;
            }

            &:hover td {
                background-color: rgb(235, 235, 235);
            }
        }

        .priority,
        .onloan {
            td {
                background-color: rgb(255, 100, 100);
            }

            &:hover td {
                background-color: rgb(255, 80, 80);
            }
        }
    }
}

.nowrap {
    white-space: nowrap;
}

.autocomplete {
    box-sizing: border-box;

    .active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .options {
        position: absolute;
        box-sizing: border-box;
        max-height: 300px;
        z-index: 10;
        overflow-y: scroll;
        font-size: 18px;
        background-color: white;
        border: black 1px solid;
        border-top: none;
        border-bottom-left-radius: 10px;

        .option {
            display: flex;
            box-sizing: border-box;
            padding: 5px;
            cursor: pointer;
            align-items: center;

            i {
                padding: 5px;
                padding-right: 10px;
                color: rgb(50, 50, 50);
            }
        }

        .hovered {
            background-color: rgb(225, 225, 225);
        }
    }
}

.troubleshooting-payment-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5px 15px;

    &>span {
        display: flex;
        gap: 5px;
    }

    button {
        padding: 5px;
        margin: 0;
        font-size: 15px;
    }
}