.newSparePart {

    .form {
        padding: 5px;

        .field {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 5px;
            padding: 2px 0;
            font-size: 18px;

            input {
                width: 250px;
            }

            &.large input {
                width: 50%;
                min-width: 450px;
            }
        }

        @media (orientation: portrait) {
            .field {
                input {
                    width: 100%;
                }

                &.large input {
                    min-width: 100%;
                }
            }
        }
    }
}

.sparePart {
    text-align: center;
    font-size: 18px;

    .subtitle {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 24px;
    }

    .field-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 30px;

        .field {
            display: flex;
            flex-direction: column;

            input {
                width: 20vw;
                min-width: 250px;
                margin: 0 auto;
            }
        }
    }

    button {
        margin-top: 15px;
    }
}