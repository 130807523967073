.equipmentsBrand {
    text-align: center;
    font-size: 18px;

    .subtitle {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 24px;
    }

    input {
        display: block;
        width: 20%;
        min-width: 250px;
        margin: 0 auto;
    }

    button {
        margin-top: 15px;
    }
}