.equipment {
    text-align: center;
    font-size: 18px;

    .subtitle {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 24px;
    }

    .field-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 30px;

        .field {
            display: flex;
            flex-direction: column;

            input {
                width: 20vw;
                min-width: 250px;
                margin: 0 auto;
            }
        }
    }

    .autocomplete {
        text-align: initial;
    }

    button {
        margin-top: 15px;
    }
}