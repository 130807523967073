.statistics {
    .lists {
        overflow-x: auto;
        white-space: nowrap;

        button {
            font-size: 16px;
            background-color: rgb(255, 255, 255);

            &:hover,
            &.selected {
                background-color: rgb(225, 225, 225);
            }
        }
    }

    .box {
        width: 50%;
        min-width: 750px;
    }
}