.file {
    text-align: center;
    font-size: 18px;

    .subtitle {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 24px;

        a {
            font-size: 16px;
        }
    }

    .client-equipment-table {
        overflow-x: auto;

        table {
            border-collapse: collapse;
            margin: 0 auto;

            th,
            td {
                padding: 7px;
                border: 1px solid black;
            }
        }
    }

    .field-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 30px;

        .field {
            display: flex;
            flex-direction: column;

            input:not([type]),
            input[type=text],
            select {
                width: 20vw;
                min-width: 250px;
                margin: 0 auto;
            }

            input[type=checkbox] {
                width: 16px;
                height: 16px;
                margin: auto;
            }
        }
    }

    .comments {
        overflow-x: auto;

        table {
            border-collapse: collapse;
            margin: 0 auto;

            td {
                padding: 7px;
                border: 1px solid black;
            }
        }

        .comment {
            input {
                width: 100%;
                min-width: 600px;
            }

            button {
                margin: 2px;
                padding: 5px;
                font-size: 16px;
            }
        }
    }

    .loans {
        overflow-x: auto;

        table {
            border-collapse: collapse;
            margin: 0 auto;

            th,
            td {
                padding: 7px;
                border: 1px solid black;

                &.clickable {
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(235, 235, 235);
                    }
                }
            }
        }
    }

    .images {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        .image {
            position: relative;
            max-width: 600px;

            img {
                max-width: 100%;
                cursor: pointer;
            }

            div {
                position: absolute;
                top: 0;
                right: 0;
                margin: 10px 5px;

                button {
                    margin: 0 5px;
                    font-size: 14px;
                }
            }
        }
    }

    input[type=file] {
        display: none;
    }

    button,
    .button {
        margin-top: 15px;
    }
}