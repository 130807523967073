.login {
    text-align: center;
    margin: 20px;

    input {
        margin: 5px;
        padding: 5px;
        border: 1px solid black;
        border-radius: 7px;
        outline: none;
        font-size: 16px;
    }
}
