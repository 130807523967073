.home {
    .lists {
        overflow-x: auto;
        white-space: nowrap;

        button {
            font-size: 16px;
            background-color: rgb(255, 255, 255);

            &:hover,
            &.selected {
                background-color: rgb(225, 225, 225);
            }
        }
    }

    .files {
        margin-top: 10px;

        table {
            width: 100%;
        }
    }
}